<template>
  <div class="Nav">
    <div class="content_center">
      <div class="Nav-left">
        <template v-for="(nav, index) in navList">
          <router-link
            :key="index"
            :to="nav.url"
            :class="['item', { active: index === activeIndex }]"
            >
            <!-- <span @click="onClickNav(index)">{{ nav.name }}</span> -->
            {{ nav.name }}
            </router-link
          >
        </template>
      </div>
      <div class="Nav-right">
        <a href="javascript:void(0)" @click="goLogin">登录</a>
        <!-- <router-link to="/register">注册</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    // activeIndex: {
    //   type: Number,
    //   default: 0
    // },
  },
  data() {
    return {
      activeIndex: 0,
      navList: [
        // { name: "首页", path: "/" },
        // { name: "关于我们", path: "/about" },
        // { name: "关务服务", path: "/customs" },
        // { name: "业务领域", path: "/business" },
        // { name: "新闻动态", path: "/news" },
        // { name: "政务法规", path: "/news?anchor=policy" },
        // { name: "联系我们", path: "/about?anchor=anchor_g" },
      ]
    };
  },
  watch: {
    '$route': {
      immediate: true,
      handler(route) {
        this.getList(route);
      }
    }
  },
  mounted () {
  },
  methods: {
    async getList(route) {
      const res = await this.$request.get(`/navigation/data`);
      this.navList = res.data;
      let curActive = '';
      if(route.fullPath.indexOf('?') > -1) {
        curActive = route.fullPath.split('?')[1];
        this.activeIndex = this.navList.findIndex(item => item.url.endsWith(curActive));
      } else {
        this.activeIndex = this.navList.findIndex(item => (item.name === route.meta.title));
      }
    },
    goLogin() {
      window.open(`${location.origin}${location.pathname}#/login`);
    }
  },
};
</script>

<style lang="scss" scoped>
.Nav {
  width: 100%;
  height: 50px;
  padding-bottom: 15px;
  line-height: 50px;
  // background-color: rgba(15,95,213,0.6);
  .content_center {
    display: flex;
    justify-content: space-between;
  }
  &-left {
    display: flex;
    line-height: 50px;
    // margin-left: 44px;
    .item {
      // width: 134px;
      margin-right: 60px;
      text-align: center;
      // padding: 0 20px;
      // margin: 0 20px;
      color: #fff;
      font-size: 16px;
      &.active {
        background: url("~@/assets/images/home/nav_active_bg.jpg") center bottom no-repeat;
        // border-bottom: 3px solid #fff;
        // background-position-y: 50%;
      }
    }
  }
  &-right {
    line-height: 50px;
    margin-left: 200px;
    color: #fff;
    a {
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      &:hover{
        color: $theme;
      }
    }
  }
}
@media screen and (max-width: 1365px) {
  .Nav{
    .content_center{
      width: 1200px;
    }
    &-right{
      margin-left: 50px;
    }
  }
}
</style>
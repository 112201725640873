<template>
  <div class="Hot">
    <div class="Hot-title">实时热点</div>
    <div class="Hot-content">
      <template v-for="(item, index) in list">
        <div :key="index" class="Hot-content-item" @click="handleViewDetail(item.articleId)">
          {{ item.title }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Hot',
    data() {
      return {
        list: []
      }
    },
    mounted () {
      this.getList();
    },
    methods: {
      async getList() {
        const res = await this.$request.get('/article/hot', { params: { size: 5 } });
        this.list = res.data;
      },
      handleViewDetail(id) {
        this.$router.push({ name: 'News.Details', params: { id } })
      }
    }
  }
</script>

<style lang="scss" scoped>
.Hot{
  margin-bottom: 50px;
  &-title{
    font-size: 20px;
    color: #171717;
  }
  &-content{
    width: 320px;
    height: 250px;
    padding: 30px 30px;
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    box-sizing: border-box;
    &-item{
      width: 100%;
      line-height: 40px;
      color: #5F5F6A;
      // font-size: 13px;
      white-space:nowrap;
      text-overflow :ellipsis;
      overflow : hidden;
      cursor: pointer;
      &:hover{
        color: #3484FB;
      }
    }
  }
}
</style>
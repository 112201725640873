<template>
  <div class="News">
    <SubTopBanner :topBannerUrl="top_banner">
      <HeaderNav :navIndex="4" />
    </SubTopBanner>
    <Part />
    <Footer />
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav.vue';
  import Part from './Part.vue';
  import top_banner from '@/assets/images/news/top_banner.jpg';
  export default {
    name: 'News',
    components: {
      HeaderNav,
      Part,
    },
    data() {
      return {
        top_banner
      }
    },
  }
</script>

<style lang="scss" scoped>
.News{

}
@media screen and (max-width: 1519px) {
  .News{
    ::v-deep(.Part){
      width: 1280px;
      .Part-container-left{
        width: 960px;
        padding: 0 30px 30px;
        .List-item-right{
          .title,
          .digest{
            width: 75%;
          }
        }
      }
    }
    ::v-deep(.Platform){
      .Platform-container-bottom{
        width: 1280px;
        .Platform-container-bottom-item{
          width: 630px;
        }
      }
    }
  }
}
</style>
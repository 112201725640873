<template>
  <div class="Activity">
    <div class="Activity-title">最新活动</div>
    <div class="Activity-content">
      <template v-for="(item, index) in list">
        <div :key="index" class="Activity-content-item" @click="handleViewDetail(item.articleId)">
          {{ item.title }}
        </div>
      </template>
    </div>
    <!-- <template v-for="(item, index) in list">
      <div :key="index" class="Activity-item">
        <img :src="item.image" >
      </div>
    </template> -->
  </div>
</template>

<script>
  export default {
    name: 'Activity',
    data() {
      return {
        list: []
      }
    },
    mounted () {
      this.getList();
    },
    methods: {
      async getList() {
        const res = await this.$request.get('article/recommend-stat', { params: { size: 6 } });
        const { articles } = res.data;
        this.list = articles;
      }
    },
  }
</script>

<style lang="scss" scoped>
.Activity{
  &-title{
    font-size: 20px;
    color: #171717;
  }
  &-content{
    width: 320px;
    height: 250px;
    padding: 30px 30px;
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    box-sizing: border-box;
    &-item{
      width: 100%;
      line-height: 40px;
      color: #5F5F6A;
      // font-size: 13px;
      white-space:nowrap;
      text-overflow :ellipsis;
      overflow : hidden;
      cursor: pointer;
      &:hover{
        color: #3484FB;
      }
    }
  }
  &-item{
    width: 320px;
    height: 120px;
    margin-top: 20px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<template>
  <div class="HeaderNav">
    <Header />
    <Nav :activeIndex="navIndex" />
  </div>
</template>

<script>
  import Header from '@/components/Header.vue';
  import Nav from "@/components/Nav.vue";
  export default {
    name: 'HeaderNav',
    components: {
      Header,
      Nav,
    },
    props: {
      navIndex: {
        type: Number,
        default: 0
      },
    },
  }
</script>

<style lang="scss" scoped>
.HeaderNav{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: rgba($color: #000000, $alpha: 0.2);
}
</style>
<template>
  <div class="content_center_medium Part">
    <!-- <el-menu :default-active="activeTabName" mode="horizontal" @select="handleSelect">
      <template v-for="item in tabList">
        <el-menu-item :key="item.id" :index="item.id">{{ item.categoryName }}</el-menu-item>
      </template>
    </el-menu> -->
     <el-tabs class="Part-tab" v-model="activeTabName" @tab-click="handleTabClick">
      <template v-for="item in tabList">
        <el-tab-pane :key="item.id" :label="item.categoryName" :name="`anchor_${item.id}`"></el-tab-pane>
      </template>
    </el-tabs>
    <div class="Part-container">
      <div class="Part-container-left">
        <router-view :categoryId="categoryId" />
      </div>
      <div class="Part-container-right">
        <Hot />
        <Activity />
      </div>
    </div>
  </div>
</template>

<script>
// import Details from './Details.vue'
import Hot from './Hot.vue'
import Activity from './Activity.vue'
  export default {
    name: 'Part',
    components: {
      Hot,
      Activity,
    },
    data() {
      return {
        activeTabName: 'anchor_5',
        tabList: [],
      }
    },
    computed: {
      categoryId() {
        return this.activeTabName.split('_')[1] 
      }
    },
    watch: {
      '$route': {
        // immediate: true,
        handler(route) {
          console.log(route);
          this.activeTabName = route?.query?.tab || 'anchor_5';
        }
      }
    },
    mounted () {
      this.getCategory();
    },
    methods: {
      async getCategory() {
        const res = await this.$request.get(`/article/categoryList`);
        this.tabList = res.data;
      },
      handleTabClick() {
        this.$router.push(`/news?tab=${this.activeTabName}`)
      },
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      }
    },
  }
</script>

<style lang="scss" scoped>
.Part{
  margin: 50px auto 100px;
  ::v-deep(&-tab){
    .el-tabs__nav-wrap{
      &::after{
        background-color: transparent;
      }
      .el-tabs__nav{
        .el-tabs__item{
          font-size: 20px;
          // color: #171717;
          font-weight: 700;
        }
      }
    }
  }
  &-container{
    display: flex;
    justify-content: space-between;
    &-left{
      width: 1180px;
      padding: 0 60px 90px;
      margin-top: 40px;
      background: #FFFFFF;
      box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
    }
    &-right{
      flex: 1;
      margin-left: 20px;
    }
  }
}
</style>